import React from 'react'
import _ from 'lodash'
import styles from './curriculum-items.module.css'
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {options} from "../templates/renderer";

export default ({data}) => {

  const items = _.groupBy(data, 'category');

  return (
    <div className={styles.curriculumItems}>
      <div className={styles.curriculumItems__full}>
        <h1 className={styles.curriculumItems__title}>Ausbildung</h1>
        { items['Ausbildung'] && _.orderBy(items['Ausbildung'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
                <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
      <div className={styles.curriculumItems__col3}>
        <h1 className={styles.curriculumItems__title}>Performances</h1>
        { items['Performance'] && _.orderBy(items['Performance'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
      <div className={styles.curriculumItems__col3}>
        <h1 className={styles.curriculumItems__title}>Solo-Ausstellungen</h1>
        { items['Solo-Ausstellung'] && _.orderBy(items['Solo-Ausstellung'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
        <h1 className={styles.curriculumItems__subtitle}>Publikation</h1>
        { items['Publikation'] && _.orderBy(items['Publikation'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
      <div className={styles.curriculumItems__col3}>
        <h1 className={styles.curriculumItems__title}>Gruppenausstellungen</h1>
        { items['Gruppen-Ausstellung'] && _.orderBy(items['Gruppen-Ausstellung'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
        <h1 className={styles.curriculumItems__subtitle}>Ausstellungskataloge</h1>
        { items['Ausstellungskataloge'] && _.orderBy(items['Ausstellungskataloge'],'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div className={styles.item__content__title}>{item.title}</div>
              { item.description &&
                <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
        <div className={styles.curriculumItems__full}>
            <h1 className={styles.curriculumItems__title}>Arbeitserfahrung</h1>
            { items['Arbeitserfahrung'] && _.orderBy(items['Arbeitserfahrung'], 'date', 'desc').map(item => (
                <div className={styles.item} key={item.date + '_' + item.title}>
                    <div className={styles.item__period}>{item.period}</div>
                    <div className={styles.item__content}>
                        <div>{item.title}</div>
                        { item.description &&
                        <div>{renderRichText(item.description, options)}</div>
                        }
                    </div>
                </div>
            ))
            }
        </div>
      <div className={styles.curriculumItems__full}>
        <h1 className={styles.curriculumItems__title}>Presse</h1>
        { items['Presse'] && _.orderBy(items['Presse'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
      <div className={styles.curriculumItems__full}>
        <h1 className={styles.curriculumItems__title}>Person</h1>
        { items['Person'] && _.orderBy(items['Person'], 'date', 'desc').map(item => (
          <div className={styles.item} key={item.date + '_' + item.title}>
            <div className={styles.item__period}>{item.period}</div>
            <div className={styles.item__content}>
              <div>{item.title}</div>
              { item.description &&
              <div>{renderRichText(item.description, options)}</div>
              }
            </div>
          </div>
        ))
        }
      </div>
    </div>
  )
};
