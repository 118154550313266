import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import CurriculumItems from '../components/curriculum-items'
import {graphql} from "gatsby";

class CurriculumIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [curriculum] = get(this, 'props.data.allContentfulCurriculum.edges')
    const curriculumItems = get(this, 'props.data.allContentfulCurriculumEintrag.edges').map((edge) => edge.node)

    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Curriculum'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <div className="with-padding">
          <h1>{curriculum.node.title}</h1>
          <div dangerouslySetInnerHTML={{__html: curriculum.node.introduction.childMarkdownRemark.html}}></div>
          <CurriculumItems data={curriculumItems} />
        </div>
      </Layout>
    )
  }
}

export default CurriculumIndex

export const pageQuery = graphql`
  query CurriculumQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCurriculum(
      filter: { contentful_id: { eq: "2P31X2yH9sORql8DMtWRUq" } }
    ) {
      edges {
          node {
            title
            introduction {
              childMarkdownRemark {
                 html
              }
            }
          }
        }
    }
    allContentfulCurriculumEintrag {
      edges {
        node {
          category
          date
          period
          title
          description {
            raw
          }
        }
      }
    }
  }
`
